import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import usePositionFooter from "../util/position-footer"

export default function Home({ data, location }) {
  const [copyrightFooterRef] = usePositionFooter(location)
  return (
    <Layout location={location} ref={copyrightFooterRef}>
      <Row style={{ flexGrow: 1 }}>
        <Col style={{ textAlign: "center" }}>
          <iframe
            title="matterport"
            width="100%"
            height="100%"
            src="https://my.matterport.com/show/?m=pg5EH5SnpAz&vr=0"
            frameborder="0"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(
      filter: { sourceInstanceName: { eq: "contact" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
